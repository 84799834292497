import { useAuthContext } from "../../utils/misc/AuthContext"

function SetupZendesk() {
  const { currentUser } = useAuthContext()

  if (typeof window === "undefined") {
    return null
  }

  const zE = (window as any).zE

  if (zE) {
    zE("webWidget", "setLocale", "en")

    if (currentUser) {
      zE("webWidget", "updateSettings", {
        webWidget: {
          zIndex: 100,
          contactForm: {
            fields: [
              { id: "name", prefill: { "*": `${currentUser.firstName} ${currentUser.lastName}` } },
              { id: "email", prefill: { "*": currentUser.email } },
            ],
          },
        },
      })
    }
  }

  return null
}

export default SetupZendesk
