import * as Sentry from "@sentry/browser"
import { RewriteFrames } from "@sentry/integrations"
import getConfig from "next/config"

if (process.env.SENTRY_DSN) {
  const config = getConfig()
  const distDir = `${config?.serverRuntimeConfig?.rootDir ?? process.cwd() ?? ""}/.next`
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
    integrations: [
      new RewriteFrames({
        iteratee: (frame: any) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next")
          return frame
        },
      }),
    ],
    dsn: process.env.SENTRY_DSN,
    beforeSend(event, hint) {
      const error = hint?.originalException
      if (error && typeof error !== "string") {
        switch (error.name) {
          case "AuthenticationError":
          case "AuthorizationError":
          case "NotFoundError":
          case "ChunkLoadError":
            return null
        }
      }
      return event
    },
  })
}

export default Sentry
