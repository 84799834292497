import { createContext, useContext } from "react"

interface LocalContextType {
  activeLocale: string | null
  setLocale: (locale: string | null) => void
  isRTL: boolean
}

export const defaultLocaleContext: LocalContextType = {
  activeLocale: null,
  setLocale: () => {},
  isRTL: false,
}

const LocaleContext = createContext<LocalContextType>(defaultLocaleContext)

export default LocaleContext

export const useLocaleContext = () => {
  return useContext(LocaleContext)
}
