import { useQuery } from "blitz"
import { QueryCacheFunctions } from "next/dist/data-client/react-query-utils"
import { AsyncFunc, FirstParam, PromiseReturnType } from "next/dist/types/utils"
import { useEffect } from "react"

export const StableQueryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  cacheTime: 1000 * 60 * 60, // 1 hour,
} as const

export function useStableQuery<
  T extends AsyncFunc,
  TResult = PromiseReturnType<T>,
  TError = unknown,
  TSelectedData = TResult
>(queryFn: T, params: FirstParam<T>): [TSelectedData, { refetch: () => void }] {
  return useQuery(queryFn, params, StableQueryOptions)
}

export function useControlledQuery<
  T extends AsyncFunc,
  TResult = PromiseReturnType<T>,
  TError = unknown,
  TSelectedData = TResult
>(
  queryFn: T,
  params: FirstParam<T>
): [
  TSelectedData | undefined,
  { isLoading: boolean; refetch: () => void } & QueryCacheFunctions<TResult>
] {
  const query = useQuery(queryFn, params, { suspense: false, enabled: false })
  useEffect(() => void query[1].refetch(), [])

  return query
}
