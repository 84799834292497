import { css } from "@emotion/react"

const globalStyles = css`
  /* galano-grotesque-regular */
  @font-face {
    font-family: "Galano Grotesque";
    font-style: normal;
    font-weight: 400;
    src: url("/fonts/galano-grotesque/GalanoGrotesque-Regular.eot"); /* IE9 Compat Modes */
    src: local(""),
      url("/fonts/galano-grotesque/GalanoGrotesque-Regular.eot?#iefix") format("embedded-opentype"),
      /* IE6-IE8 */ url("/fonts/galano-grotesque/GalanoGrotesque-Regular.woff2") format("woff2"),
      /* Super Modern Browsers */ url("/fonts/galano-grotesque/GalanoGrotesque-Regular.woff")
        format("woff"),
      /* Modern Browsers */ url("/fonts/galano-grotesque/GalanoGrotesque-Regular.ttf")
        format("truetype"),
      /* Safari, Android, iOS */
        url("/fonts/galano-grotesque/GalanoGrotesque-Regular.svg#GalanoGrotesque-Regular")
        format("svg"); /* Legacy iOS */
  }
  /* galano-grotesque-medium */
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    src: url("/fonts/galano-grotesque/GalanoGrotesque-Medium.eot"); /* IE9 Compat Modes */
    src: local(""),
      url("/fonts/galano-grotesque/GalanoGrotesque-Medium.eot?#iefix") format("embedded-opentype"),
      /* IE6-IE8 */ url("/fonts/galano-grotesque/GalanoGrotesque-Medium.woff2") format("woff2"),
      /* Super Modern Browsers */ url("/fonts/galano-grotesque/GalanoGrotesque-Medium.woff")
        format("woff"),
      /* Modern Browsers */ url("/fonts/galano-grotesque/GalanoGrotesque-Medium.ttf")
        format("truetype"),
      /* Safari, Android, iOS */
        url("/fonts/galano-grotesque/GalanoGrotesque-Medium.svg#GalanoGrotesque-Medium")
        format("svg"); /* Legacy iOS */
  }
`

export default globalStyles
