import { extendTheme } from "@chakra-ui/react"
import { StepsStyleConfig as Steps } from "chakra-ui-steps"

// picked from styleguide
export const brand = {
  radiantBlue: "#066AFF",
  expertBlue: "#0F345A",
  yellowMarker: "#FFFF69",
  orange: "#e89d17",
  medicalBlue: "#BAC9E4",
  lightBlue: "#C5FFFF",
  lightGray: "#F2F2F2",
  lightGreen: "#C8D3AE",
}

const yellowMarker = {
  50: "#ffffdd",
  100: "#ffffb0",
  200: "#ffff80",
  300: "#ffff4f",
  400: "#ffff23",
  500: "#FFFF69",
  600: "#b2b306",
  700: "#7f8000",
  800: "#4c4d00",
  900: "#191a00",
}

// generated using https://smart-swatch.netlify.app/#0F345A
const expertBlue = {
  50: "#e2f3ff",
  100: "#bbd8f7",
  200: "#92bfed",
  300: "#68a5e5",
  400: "#3f8bdb",
  500: "#2872c3",
  600: "#1c5898",
  700: "#123f6d",
  800: "#062644",
  900: "#000e1b",
}

// expertBlue which is actually dark.
const trueExpertBlue = {
  50: brand.expertBlue,
  100: brand.expertBlue,
  200: brand.expertBlue,
  300: brand.expertBlue,
  400: brand.expertBlue,
  500: brand.expertBlue,
  600: expertBlue[500],
  700: expertBlue[500],
  800: expertBlue[500],
  900: expertBlue[500],
}

// generated using https://smart-swatch.netlify.app/#C5FFFF
const lightBlue = {
  50: "#deffff",
  100: "#b3ffff",
  200: "#86fefe",
  300: "#5cfefe",
  400: "#41fefe",
  // 500: "#35e4e4",
  500: "#C5FFFF",
  600: "#25b1b2",
  700: "#157e7f",
  800: "#004c4d",
  900: "#001a1b",
}

export const colors = {
  brand,
  expertBlue,
  trueExpertBlue,
  lightBlue,
  yellowMarker,
}

export const fonts = {
  galanoGrotesque:
    "'Galano Grotesque', 'galano grotesque', 'Source Sans', 'Helvetica Neue', Helvetica, 'Arial Black', Arial, sans-serif",
}

const Button = {
  baseStyle: {
    fontWeight: "bold",
  },
  sizes: {
    sm: {
      px: 8,
      py: 3,
      borderRadius: "16px",
    },
    md: {
      px: 10,
      py: 4,
      borderRadius: "20px",
    },
    lg: {
      px: 14,
      py: 5,
      borderRadius: "24px",
    },
  },
}

const Input = {
  variants: {
    outline: {
      field: { bg: "white" },
    },
    filled: {
      field: {
        color: "black",
        _focus: {
          bg: "white",
        },
      },
    },
  },
}

const Alert = {
  baseStyle: {
    borderRadius: "4px",
    radii: "4px",
    container: {
      borderRadius: "base",
      radii: "4px",
    },
  },
}

const Select = {
  parts: ["icon"],
  baseStyle: {
    icon: {
      _rtl: {
        left: "0.5em",
        right: "auto",
      },
    },
  },
}

const components = {
  Button,
  Input,
  Alert,
  Select,
  Steps,
}

const theme = extendTheme({
  colors,
  fonts: { heading: fonts.galanoGrotesque, body: fonts.galanoGrotesque },
  components,
})

export default theme
