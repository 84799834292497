import "app/core/utils/i18n"
import "../css/AddressFields.css"
import "./syntax.css"

import { ChakraProvider, useToast } from "@chakra-ui/react"
import { Global } from "@emotion/react"
import { globalStyles, theme } from "app/theme"
import AuthContext, { useAuthContext } from "app/utils/misc/AuthContext"
import {
  AppProps,
  AuthenticationError,
  AuthorizationError,
  ErrorBoundary,
  ErrorFallbackProps,
  Routes,
  useMutation,
  useQueryErrorResetBoundary,
  useRouter,
} from "blitz"
import Sentry from "integrations/sentry"
import { FC, Suspense, useEffect } from "react"
import { useTranslation } from "react-i18next"

import FullScreenLoader from "../../common/components/FullScreenLoader"
import updateCurrentProfile from "../../user/mutations/updateLanguage"
import getCurrentUser from "../../user/queries/getCurrentUser"
import { useStableQuery } from "../../utils/hooks/queries"
import LocaleContext from "../../utils/misc/LocaleContext"
import ErrorComponent from "../components/ErrorComponent"
import SetupZendesk from "../components/SetupZendesk"

const AppWithLocale: FC<AppProps> = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout || ((page) => page)
  const { currentUser, refetchUser } = useAuthContext()
  const { preferredLocale } = currentUser || {}
  const { i18n } = useTranslation()

  useEffect(() => {
    if (preferredLocale && preferredLocale !== i18n.language) {
      i18n.changeLanguage(preferredLocale)
    }
  }, [i18n, preferredLocale])

  useEffect(() => {
    const lang = i18n.language
    const dir = i18n.language === "ar" ? "rtl" : "ltr"
    if (document.documentElement.lang !== lang) {
      document.documentElement.lang = lang
    }
    if (document.documentElement.dir !== dir) {
      document.documentElement.dir = dir
    }
  }, [i18n.language])

  const [updateCurrentProfileMutation] = useMutation(updateCurrentProfile)

  const handleSetLocale = async (newLocale: string) => {
    i18n.changeLanguage(newLocale)
    if (currentUser && newLocale !== preferredLocale) {
      await updateCurrentProfileMutation({ preferredLocale: newLocale })
      await refetchUser()
    }
  }

  return (
    <LocaleContext.Provider
      value={{
        activeLocale: i18n.language,
        setLocale: handleSetLocale,
        isRTL: i18n.language === "ar",
      }}
    >
      {getLayout(<Component {...pageProps} />)}
    </LocaleContext.Provider>
  )
}

const AppWithAuth: FC<AppProps> = (props) => {
  const { currentUser, refetchUser } = useCurrentUser()

  useEffect(() => {
    if (currentUser?.email) {
      Sentry.setUser({ email: currentUser.email })
    }
  }, [currentUser])

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        refetchUser,
      }}
    >
      <SetupZendesk />
      <AppWithLocale {...props} />
    </AuthContext.Provider>
  )
}

const App: FC<AppProps> = (props) => {
  return (
    <ChakraProvider theme={theme}>
      <Global styles={globalStyles} />
      <ErrorBoundary
        FallbackComponent={RootErrorFallback}
        onReset={useQueryErrorResetBoundary().reset}
        onError={(error, componentStack) => {
          Sentry.captureException(error, { contexts: { react: { componentStack } } })
        }}
      >
        <Suspense fallback={<FullScreenLoader />}>
          <AppWithAuth {...props} />
        </Suspense>
      </ErrorBoundary>
    </ChakraProvider>
  )
}

export default App

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  const router = useRouter()
  const toast = useToast()
  if (error instanceof AuthenticationError) {
    // the redirect part used to be in the layout, but we need to redirect here because the layout is not rendered
    // this error is thrown when the session expired after timeout
    // the next check is a workaround to prevent multiple toasts
    const toastId = "session-expired-toast"
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        title: "Your session has expired, please log in again.",
        status: "error",
      })
    }
    router.push(Routes.LoginPage())
    return null
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    return (
      <ErrorComponent statusCode={error.statusCode || 400} title={error.message || error.name} />
    )
  }
}

const useCurrentUser = () => {
  const [currentUser, { refetch }] = useStableQuery(getCurrentUser, null)
  return { currentUser, refetchUser: refetch }
}
