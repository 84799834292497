import { Center, CenterProps, Spinner } from "@chakra-ui/react"
import React, { FC } from "react"

interface Props extends CenterProps {}

const FullScreenLoader: FC<Props> = ({ children, ...restProps }) => {
  return (
    <Center position="fixed" inset={0} background="white" {...restProps}>
      <Spinner color="brand.expertBlue" size="xl" emptyColor="blue.50" />
      {children}
    </Center>
  )
}

export default FullScreenLoader
