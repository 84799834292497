import { createContext, useContext } from "react"

import { CurrentUser } from "../../user/queries/getCurrentUser"

interface AuthContextType {
  currentUser: CurrentUser
  refetchUser: () => void
}

export const defaultAuthContext: AuthContextType = {
  currentUser: null,
  refetchUser() {},
}

const AuthContext = createContext<AuthContextType>(defaultAuthContext)

export default AuthContext

export const useAuthContext = () => {
  return useContext(AuthContext)
}
