import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import PhraseInContextEditorPostProcessor from "i18next-phrase-in-context-editor-post-processor"
import { initReactI18next } from "react-i18next"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(
    new PhraseInContextEditorPostProcessor({
      phraseEnabled:
        "true" === process.env.NEXT_PUBLIC_ACTIVATE_PHRASE_IN_CONTEXT &&
        typeof window !== "undefined" &&
        (window?.location?.href?.indexOf("phrase") ?? -1) !== -1,
      projectId: "dadcf3c44a4a27a3e5b5f95b84803c93",
      autoLowercase: false,
    })
  )
  .init({
    // The next line is necessary in order to load all languages. It should not be just "en".
    fallbackLng: (process.env.NEXT_PUBLIC_SUPPORTED_LANGUAGES || "en").split(","),
    supportedLngs: (process.env.NEXT_PUBLIC_SUPPORTED_LANGUAGES || "en").split(","),
    debug: process.env.NODE_ENV === "development",
    defaultNS: "main",
    ns: "main",
    postProcess: ["phraseInContextEditor"],
  })

export default i18n
